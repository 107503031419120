import { defineStore } from 'pinia'

export const useSession = defineStore('sessionStore', {
  state: () => ({
    browser: '',
    deferredPrompt: null,
    token: null,
    notifications: false,
    homeScreen: true,
    showIntro: true
  }),
  persist: true,
  getters: {
    getBrowser (state) {
      return state.browser
    },
    getHomescreen (state) {
      return state.homeScreen
    },
    getToken (state) {
      return state.token
    },
    getIntro (state) {
      return state.showIntro
    }
  },
})
