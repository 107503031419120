<template>
  <div>
    <body class="d-flex flex-column min-vh-100 w-screen overscroll-contain">
    <div class="container all-row">
      <div class="row all-row">
        <div class="col-xl-3 col-md-3 col-sm-3 col-xs-3 all-row"></div>
        <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12 all-row">
          <div v-if="$router.currentRoute.value.name === 'New User'">
            <home-header></home-header>
          </div>
          <div v-else-if="$router.currentRoute.value.name === 'Promotion'">
            <header-promotion></header-promotion>
          </div>
          <div v-else>
            <header-component></header-component>
          </div>
          <router-view></router-view>

          <div v-if="$router.currentRoute.value.name == 'Home' || $router.currentRoute.value.name == 'Result'">
            <!--    v-if="deferredPrompt"-->
            <div v-if="this.session.getHomescreen">
              <div class="mt-auto" v-if="this.browser == 'chrome'">
                <div v-if="deferredPrompt">
                  <div class="row">
                    <div class="col-10 offset-1">
                      <p class="strburst-a2hs">Install our free app<br>
                        to access your card offline<br>
                        and save on data.
                      </p>
                    </div>
                    <div class="row">
                      <div class="col-4">
                        <button
                          ref="addBtn"

                          class="btn btn-dismiss"
                          @click="dismiss">
                          No thanks
                        </button>
                      </div>
                      <div class="col-7 text-left">
                        <button
                          ref="addBtn"
                          class="btn btn-primary btn-a2hs"
                          @click="install">
                          Add to Home Screen
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div v-else>
                <div class="row">
                  <div class="col-10 offset-1">
                    <br>
                    <p class="strburst-a2hs">Add our app to your home screen<br>
                      to access your card offline
                    </p>
                  </div>
                  <div class="row">
                    <div class="col-5">
                      <button
                        ref="addBtn"

                        class="btn btn-dismiss"
                        @click="dismiss">
                        Maybe later
                      </button>
                    </div>
                    <div class="col-7 text-left">
                      <button
                        ref="addBtn"
                        class="btn btn-primary btn-a2hs"
                        @click="homescreen">
                        Add to Home Screen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br>

    <footer-component></footer-component>
    </body>

  </div>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&family=Ubuntu:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap');

#app {

  /*-webkit-font-smoothing: antialiased*/
  /*-moz-osx-font-smoothing: grayscale*/
  /*text-align: center*/
  /*color: #2c3e50*/
}

html, body {
  @import '../input.css';
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

.strburst-a2hs {
  font-size: 12pt;
  margin-bottom: 10pt;
  color: #893494;
}

.btn-a2hs {
  font-size: 12pt;
  float: right;
  display: inline;
  border-color: #893494;
  border-style: solid;
  border-width: 1pt;
  background-color: white;
  /*border-radius: 20pt;*/
  color: #893494;
}

.btn-dismiss {
  float: left;
  font-size: 12pt;
  display: inline;
  text-decoration-line: underline;
}

div.all-row {
  margin: unset;
  padding: unset;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.bm-menu {
  background: white !important;
}

.bm-burger-bars {
  background: #fdb913 !important;
}

a.router-link-exact-active {
  color: #FDB913 !important;
}

</style>
<script>
import HeaderComponent from '@/views/HeaderComponent'
import FooterComponent from '@/views/FooterComponent'
import HeaderPromotion from '@/views/HeaderPromotion'
import HomeHeader from '@/views/HomeHeader'
import Cookies from 'js-cookie'
import { getToken } from 'firebase/messaging'
import { useUser } from '@/stores/useUser'
import CardDataService from '@/services/CardDataService'
import { useSession } from '@/stores/useSession'
import { useDeals } from '@/stores/useDeals'
// import browserDetect from 'vue-browser-detect-plugin'

// import { ref } from 'vue'

export default {
  components: {
    HeaderPromotion,
    FooterComponent,
    HeaderComponent,
    HomeHeader,
  },
  setup () {
  },
  data () {
    return {
      deferredPrompt: null,
      showNotification: false,
      browser: null,
      session: null,
      showHomescreen: true,

    }
  },
  created () {
    this.browser = this.fnBrowserDetect()
    this.session = useSession()
    this.session.$patch({
      browser: this.browser
    })

    if (Cookies.get('add-to-home-screen') === undefined) {
      this.showHomescreen = true
    } else {
      this.showHomescreen = false
    }

    if (this.browser == 'chrome') {
      window.addEventListener('beforeinstallprompt', e => {
        e.preventDefault()
        // Stash the event so it can be triggered later.
        if (Cookies.get('add-to-home-screen') === undefined) {
          this.deferredPrompt = e
          this.session.$patch({
            deferredPrompt: e
          })
          this.session.$patch({
            homeScreen: true
          })
        }else{
          this.session.$patch({
            homeScreen: false
          })
        }
      })
      window.addEventListener('appinstalled', () => {
        this.deferredPrompt = null
        this.session.$patch({
          deferredPrompt: null
        })
      })
    }
    const user = useUser()
    if (user.cardUrl !== '') {
      this.updateDeals()
      this.updateSavings()
    }

    // let notificationCookie = Cookies.get('request-notifications')
    //this.showNotification = true
    //console.log(notificationCookie)
    // if (notificationCookie != undefined) {
    //   this.showNotification = false
    // }
    // getToken(this.$messaging, { vapidKey: 'BAeN8iqNHdcBMlZiK2k9OP5f9MrxPXmw2KMBveWQPSj5Y9sU8a6TGnOHMBjmfBbQd_6cpX5HvHKzcpqx26lyNh0' })
    //   .then((currentToken) => {
    //     if (currentToken) {
    //       this.showNotification = false
    //     }
    //   })
  },
  methods: {
    async dismiss () {
      Cookies.set('add-to-home-screen', null, { expires: 7 })
      this.session.$patch({
        homeScreen: false
      })

      this.deferredPrompt = null
    },
    async install () {
      this.deferredPrompt.prompt()
    },
    async allow () {
      console.log(this.$messaging)
      getToken(this.$messaging, { vapidKey: 'BAeN8iqNHdcBMlZiK2k9OP5f9MrxPXmw2KMBveWQPSj5Y9sU8a6TGnOHMBjmfBbQd_6cpX5HvHKzcpqx26lyNh0' })
        .then((currentToken) => {
          if (currentToken) {
            console.log('client token', currentToken)
            const user = useUser()
            const tokenData = {
              userId: user.getUserId,
              token: currentToken
            }
            CardDataService.sendToken(tokenData).catch((err) => {
              console.log('Send Token Error', err)
            })
          } else {
            console.log('No registration token available. Request permission to generate one.')
          }
        }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err)
      })
    },
    dismissNotification () {
      Cookies.set('request-notifications', null, 12)
    },
    fnBrowserDetect () {

      let userAgent = navigator.userAgent
      let browserName
      console.log(userAgent)
      if (userAgent.match(/samsungbrowser/i)) {
        browserName = 'samsung'
      } else if (userAgent.match(/ucbrowser/i)) {
        browserName = 'uc'
      } else if (userAgent.match(/chrome|chromium|crios/i)) {
        browserName = 'chrome'
      } else if (userAgent.match(/firefox|fxios/i)) {
        browserName = 'firefox'
      } else if (userAgent.match(/safari/i)) {
        browserName = 'safari'
      } else if (userAgent.match(/opr\//i)) {
        browserName = 'opera'
      } else if (userAgent.match(/edg/i)) {
        browserName = 'edge'
      } else {
        browserName = 'other'
      }
      console.log(browserName)
      return browserName
    },
    homescreen () {
      this.$router.push('/homescreen')
    },
    updateDeals(){
      const deals = useDeals()
      CardDataService.getAllDeals().then(response => {
        deals.$patch({deals: response.data})
        console.log(response.data)
        console.log(deals.getDeals)

      })

    },
    updateSavings(){
      const user = useUser()
      CardDataService.getSavings(user.getCardId).then(response => {
        user.$patch({savings: response.data.savings})
        console.log(response.data)
      })

    },
  }
}

</script>
