//Imports
import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import App from './App.vue'
import './wcss.css'
import './registerServiceWorker'
import "bootstrap/dist/js/bootstrap.js"
import router from './router'
import {createPinia} from 'pinia'
import {BIconShare, BIconFacebook, BIconPinterest,
  BIconEnvelope, BIconWhatsapp, BIconChatDots, BIconMessenger} from 'bootstrap-icons-vue'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
//Slide
import { Slide } from 'vue3-burger-menu'
//Barcode
import VueBarcode from '@chenfengyuan/vue-barcode';
import A2Hs from '@/components/A2Hs'
import { SFacebook, SEmail, SSms, SFacebookMessenger, SPinterest, SWhatsApp } from 'vue-socials/dist/vue-socials.es'
//Firebase
import firebaseMessaging from './firebase'
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const myApp = createApp(App)
  .component('BIconMessenger', BIconMessenger)
  .component('BIconChatDots', BIconChatDots)
  .component('BIconEnvelope', BIconEnvelope)
  .component('BIconWhatsapp', BIconWhatsapp)
  .component('BIconPinterest', BIconPinterest)
  .component('SFacebook', SFacebook)
  .component('SEmail', SEmail)
  .component('SFacebookMessenger', SFacebookMessenger)
  .component('SSms', SSms)
  .component('SPintrest', SPinterest)
  .component('SWhatsApp', SWhatsApp)
  .component('BIconShare',BIconShare)
  .component('BIconFacebook', BIconFacebook)
  .component('Slide', Slide)
  .component(VueBarcode.name, VueBarcode)
  .component('A2Hs', A2Hs)
  .use(pinia)
  .use(router)
  .use(OneSignalVuePlugin, {
    appId: "c11f524c-a571-47d9-af33-96484302d912",
//    safari_web_id: "web.onesignal.auto.639a6cf4-24b5-4e34-8f2b-dfd5fe45e04f"
    // appId: "b8b7c5c7-4468-4f09-adf7-d2c1e3972f81",
    // safari_web_id: "web.onesignal.auto.639a6cf4-24b5-4e34-8f2b-dfd5fe45e04f",
    // notifyButton: {
    //   enable: true,
    // },
    // allowLocalhostAsSecureOrigin: true,
  })
myApp.config.globalProperties.$messaging = firebaseMessaging

  myApp.mount('#app')
