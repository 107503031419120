import http from '../http-common'

class CardDataService {
  get (id) {
    return http.get(`/card/${id}`)
  }

  create (data) {
    return http.post('/cards', data)
  }

  getAllDeals () {
    return http.get(`/deals`)
  }
  sendToken (data) {
    return http.post(`/tokens`, data)
  }

  getCard (id) {
    return http.get(`/cards/0?card=${id}`)
  }
  getSavings (id) {
    return http.get(`/savings/0?card=${id}`)
  }

  getRewards (id) {
    return http.get(`/rewards/0?card=${id}`)
  }

}

export default new CardDataService()
